.bold{
    font-weight: 700;
  }

.outline {
    -webkit-text-stroke: 2px black;
}
  
  .allcaps{
    text-transform:uppercase;
  }
  
  .entry-drikk{
    font-weight: 700;
  }
  
  .entry-black{
    color:black;
  }
  
  .entry-blue{
    color:#013DBD;
  }
  
  .entry-pink{
    color:#F42272;
  }
  
  .entry-red{
    color:red;
  }
  
  .entry-puke{
    color:#a3ce08;
  }
  
  .entry-green{
    color:green;
  }
  
  .entry-yellow{
    color:yellow;
  }

  .entry-snap{
    color:#FFFC00;
  }
  
  .entry-orange{
    color:orange;
  }
  
  .entry-purple{
    color:purple;
  }
  
  .entry-brown{
    color:brown;
  }
  
  .entry-lightblue{
    color:lightblue;
  }
  
  .entry-wine{
    color:#380015;
  }
  
  .entry-beer{
    color:#F6C101
  }
  
  .entry-snus{
    color:#48412d;
  }