.info-message-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 18px 12px 22px;
    border-radius: 500px;
    margin: 24px;
    color: white;
}

.add-container{
    padding: 12px 18px 12px 18px;
    border-radius: 500px;
    margin-left: auto;
    margin-right: auto;
    width: 25%;
    text-align: center;
    margin-top: 48px;
    color: white;
}