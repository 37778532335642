@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,200;0,300;0,400;0,500;0,600;0,900;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

/* ---------------------------------------------------------------------------------------------------------------------------------------- */
/* VARIABLES */

/* Outlines */
:root {
    --outline-m: 5px;
    --outline-s: 3px;
    --outline-xs: 1px;
}

/* Spacing */
:root {
    --spacing-s: 6px;
    --spacing-m: 12px;
    --spacing-l: 24px;
    --spacing-xl: 56px;
    --spacing-xxl: 72px;
}

/* Colors */
:root {
    --cyan: #61E8E1;
    --limegreen: #86EF9D;
    --blue: #00B2FF;
    --purple: #6224F5;
    --pink: #F42272;
    --turqoise: #03CEA4;
    --black: #000;
    --white: #FFF;
    --black-opaque: #00000061;
    --pink-opaque: #f42272c0;
}

/* Opacity */
:root {
    --opacity-low: 40%;
    --opacity-high: 80%;
}
/* ---------------------------------------------------------------------------------------------------------------------------------------- */

/* Text */
.text-h1 {
    font-weight: 700;
    font-size: 42px;
    font-style: italic;
    text-transform: uppercase;
    line-height: 100%;
}

.text-h2 {
    font-weight: 700;
    font-size: 32px;
    font-style: italic;
    text-transform: uppercase;
    line-height: 100%;
}

.text-h3 {
    font-weight: 700;
    font-size: 26px;
    font-style: italic;
    text-transform: uppercase;
}

.text-h4 {
    font-weight: 700;
    font-size: 20px;
    font-style: italic;
    text-transform: uppercase;
}

.text-force-break {
    overflow-wrap: anywhere;
}

.text-color-pink {
    color: var(--pink);
}

.text-color-black {
    color: var(--black);
}

p {
    margin: 0px;
    font-weight: 500;
    font-size: 20px;
    font-style: italic;
}

a {
    color: white;
    text-decoration: none;
}

.button-text-l {
    font-style: italic;
    font-size: 38px;
    text-align: center;
    font-weight: 700;
    user-select: none;
}

.button-text-m {
    font-style: italic;
    font-size: 26px;
    text-align: center;
    font-weight: 700;
    user-select: none;
    
}

.button-text-s {
    font-style: italic;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    user-select: none;
}

.box-button-center {
    line-height: 70px;
}

.entry-text-xxl {
    text-align: center;
    word-wrap: break-word;
    font-size: 100px;
    font-weight: 500;
    font-style: italic;
}

.entry-text-xl {
    text-align: center;
    word-wrap: break-word;
    font-size: 55px;
    font-weight: 500;
    font-style: italic;
}

.entry-text-l {
    text-align: center;
    word-wrap: break-word;
    font-size: 45px;
    font-weight: 500;
    font-style: italic;
}

.entry-text-ll {
    text-align: center;
    word-wrap: break-word;
    font-size: 40px;
    font-weight: 500;
    font-style: italic;
}

.entry-text-m {
    text-align: center;
    word-wrap: break-word;
    font-size: 35px;
    font-weight: 500;
    font-style: italic;
}

.entry-text-s {
    text-align: center;
    word-wrap: break-word;
    font-size: 30px;
    font-weight: 500;
    font-style: italic;
}
.entry-text-xs {
    text-align: center;
    word-wrap: break-word;
    font-size: 23px;
    font-weight: 500;
    font-style: italic;
}

/* Shadow */
.shadow-black {
    box-shadow: 5px 5px var(--black);
    -webkit-appearance: none;
}

.shadow-pink-opaque {
    box-shadow: 4px 4px var(--pink-opaque);
}
.shadow-pink {
    box-shadow: 4px 4px var(--pink);
}

/* Button */
.button-primary {
    height: var(--spacing-xxl);
    border-color: var(--black);
    border-style: solid;
    border-width: var(--outline-m);
    border-radius: 0;
    cursor: pointer;
}

.white-fill {
    background-color: var(--white);
}

.black-fill {
    background-color: var(--black);
}

.button-tertiary-s {
    border-bottom: var(--outline-s) solid;
    cursor: pointer;
}
.button-tertiary-m {
    border-bottom: var(--outline-s) solid;
    cursor: pointer;
}
.button-tertiary-l {
    border-bottom: var(--outline-m) solid;
    cursor: pointer;
}

h1 {
    font-weight: 700;
    font-size: 42px;
    font-style: italic;
    text-transform: uppercase;
}

h2 {
    font-weight: 500;
    font-size: 32px;
    font-style: normal;
}

/* Input */
input {
  outline:none;
  padding-left: var(--spacing-l);
  transition:.3s;
  font-family: 'Kanit', sans-serif;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  text-transform: uppercase;
  caret-color: var(--pink);
}

input:focus {
  box-shadow: 10px 10px;
}

textarea {
    outline:none;
    padding-left: var(--spacing-l);
    transition:.3s;
    font-family: 'Kanit', sans-serif;
    padding-top: 0px;
    padding-bottom: 0px;
    text-transform: uppercase;
    line-height: 1.2;
    caret-color: var(--pink);
}
textarea:focus {
    box-shadow: 20px 20px;
  }
textarea::placeholder {
    padding-top: 20px;
  }


.input-entry {
    background-color: #00000000;
    border: none;
    border-bottom: var(--outline-m) solid;
    text-transform: none;
}

.input-entry:focus {
    box-shadow: 0px 2px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--black-opaque);
  }


/* Margin */
.bottom-margin-xl {
    margin-bottom: var(--spacing-xl);
}
.bottom-margin-l {
    margin-bottom: var(--spacing-l);
}
.bottom-margin-m {
    margin-bottom: var(--spacing-m);
}

.top-margin-l {
    margin-top: var(--spacing-l);
}
.top-margin-m {
    margin-top: var(--spacing-m);
}

.left-margin-s {
    margin-left: var(--spacing-s);
}
.left-margin-m {
    margin-left: var(--spacing-m);
}


/* Tailwindy stuff */


.stretch {
    flex: 1;
}

.gap-20 {
    gap: 20px;
}


.announcement {
    animation: pulse 1.6s infinite;
}
.rotated {
    transform: rotate(-15deg);
}
@keyframes pulse {
    0% {
        transform: scale(0.90);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.90);
    }
}
.text-stroke {
    -webkit-text-stroke: 1px black;
}


.countdown-l {
    position: fixed;
    top: 0px;  /* Fixed distance from top */
    left: 30px; /* Fixed distance from left */
    z-index: -1;
    font-size: 450px;
    opacity: 20%;
    font-weight: 800;
    overflow: visible;
}

.countdown-m {
    font-size: 300px;
    position: fixed;
    top: 150px;  /* Fixed distance from top */
    left: 0px; /* Fixed distance from left */
    opacity: 20%;
    font-weight: 800;
    z-index: -1;
}

@media (orientation: landscape) {
    .countdown-l {
        left: 300px;
        top: -130px
    }
    .countdown-m {
        left: 300px;
        top: -130px
    }
}

.App {
    padding: 24px;
}

body {
    padding: 0px;
    margin: 0px;
    font-family: 'Kanit', sans-serif;
    /* touch-action: none; */
}

.App-header {
    height: 100%;
    display: flex;
    justify-content: space-between;

    flex-direction: column;
    /* font-size: calc(10px + 2vmin); */    
    color: white;
}

.swipe-area {
    height: 60%;
    display: flex;
    align-items: center;
    width: 100%;
}

.menu-container {
    padding: 24px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: calc(100vw - 80px);
    background: black;
    animation: menu-open 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 120px;
    z-index: 1;
}

.menu-item {
    height: 1vh;
    display: flex;
    flex-direction: column;
    min-height: 1vh;
}

.menu-absolute {
    position: absolute;
    top: 20vh;
    width: 100vh;
    height: 60%;
}

@keyframes menu-open {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

.menu-close{
    animation: menu-close 0.5s;
  }
@keyframes menu-close {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.stuttering-arrow-left {
    animation: stutteringMovementLeft 2s linear infinite;
    cursor: pointer;
  }
  
  @keyframes stutteringMovementLeft {
    0%, 5% {
      transform: translateX(0);
    }
    10%, 15% {
      transform: translateX(-8px);
    }
    20%, 25% {
      transform: translateX(0);
    }
    30%, 35% {
      transform: translateX(-8px);
    }
    40%, 100% {
      transform: translateX(0);
    }
  }

  .stuttering-arrow-right {
    animation: stutteringMovementRight 2s linear infinite;
    cursor: pointer;
  }
  
  @keyframes stutteringMovementRight {
    0%, 5% {
      transform: translateX(0);
    }
    10%, 15% {
      transform: translateX(8px);
    }
    20%, 25% {
      transform: translateX(0);
    }
    30%, 35% {
      transform: translateX(8px);
    }
    40%, 100% {
      transform: translateX(0);
    }
  }

.fylla-vertical-container {
    position: fixed;
    transform: scale(0.7)
}

.fylla-vertical {
    left: -171px;
    position: absolute;
    top: -35px;
}


.background {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 6375px;
    height: 100%;
    flex: 1;
    z-index: -2;
    /* background-image: url( './photos/fylla1.svg' ); */
    animation: color-change 40s infinite;
}

.happening-17th-may {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 6375px;
    height: 100%;
    flex: 1;
    z-index: -2;
    background-image: url( './photos/flagg.svg' );
}

.happening-pride {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    height: 100%;
    flex: 1;
    z-index: -2;
}

.background-child {
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    height: 100%;
    align-content: stretch;
    max-width: 100%;
    max-height: 100%;
}

@keyframes color-change {
    0% {
        background-color: var(--cyan)
    }

    20% {
        background-color: var(--limegreen)
    }

    40% {
        background-color: var(--turqoise);
    }

    60% {
        background-color: var(--blue);
    }

    80% {
        background-color: var(--purple);
    }

    100% {
        background-color: var(--cyan);
    }
}

@keyframes slidein1 {
    0% {
        transform: translateX(calc(-30%));
    }

    25% {
        transform: translateX(calc(-30% + -5%));
    }

    50% {
        transform: translateX(calc(-30% + 5%));
    }

    75% {
        transform: translateX(calc(-30% + -5%));
    }

    100% {
        transform: translateX(calc(-30%));
    }
}

@keyframes slidein2 {
    0% {
        transform: translateX(calc(-30%));
    }

    25% {
        transform: translateX(calc(-30% + 7%));
    }

    50% {
        transform: translateX(calc(-30% + -15%));
    }

    75% {
        transform: translateX(calc(-30% + -5%));
    }

    100% {
        transform: translateX(calc(-30%));
    }
}

@keyframes slidein3 {
    0% {
        transform: translateX(calc(-30%));
    }

    25% {
        transform: translateX(calc(-30% + -10%));
    }

    50% {
        transform: translateX(calc(-30% + 3%));
    }

    75% {
        transform: translateX(calc(-30% + -2%));
    }

    100% {
        transform: translateX(calc(-30%));
    }
}

@keyframes slidein4 {
    0% {
        transform: translateX(calc(-30%));
    }

    25% {
        transform: translateX(calc(-30% + 5%));
    }

    50% {
        transform: translateX(calc(-30% + -5%));
    }

    75% {
        transform: translateX(calc(-30% + 7%));
    }

    100% {
        transform: translateX(calc(-30%));
    }
}

@keyframes slidein5 {
    0% {
        transform: translateX(calc(-30%));
    }

    25% {
        transform: translateX(calc(-30% + -2%));
    }

    50% {
        transform: translateX(calc(-30% + 7%));
    }

    75% {
        transform: translateX(calc(-30% + -12%));
    }

    100% {
        transform: translateX(calc(-30%));
    }
}

.fylla1 {
    animation: slidein1 8s ease-in-out infinite;
}

.fylla2 {
    animation: slidein2 8s ease-in-out infinite;
}

.fylla3 {
    animation: slidein3 8s ease-in-out infinite;
}

.fylla4 {
    animation: slidein4 8s ease-in-out infinite;
}

.fylla5 {
    animation: slidein5 8s ease-in-out infinite;
}

@keyframes pride-anim1 {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.3);
    }

    50% {
        transform: scale(1.1);
    }

    75% {
        transform: scale(1.7);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pride-anim2 {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.7);
    }

    50% {
        transform: scale(1.4);
    }

    75% {
        transform: scale(1.6);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pride-anim3 {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.3);
    }

    50% {
        transform: scale(1.1);
    }

    75% {
        transform: scale(1.4);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pride-anim4 {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.3);
    }

    50% {
        transform: scale(1.8);
    }

    75% {
        transform: scale(1.4);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pride-anim5 {
    0% {
        transform: scale(1.1);
    }

    25% {
        transform: scale(1.7);
    }

    50% {
        transform: scale(1.2);
    }

    75% {
        transform: scale(1.4);
    }

    100% {
        transform: scale(1.1);
    }
}

@keyframes pride-anim6 {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.7);
    }

    50% {
        transform: scale(1.4);
    }

    75% {
        transform: scale(1.6);
    }

    100% {
        transform: scale(1);
    }
}

.pride-1 {
    animation: pride-anim1 5s ease-in-out infinite;
}
.pride-2 {
    animation: pride-anim2 5s ease-in-out infinite;
}
.pride-3 {
    animation: pride-anim3 5s ease-in-out infinite;
}
.pride-4 {
    animation: pride-anim4 5s ease-in-out infinite;
}
.pride-5 {
    animation: pride-anim5 5s ease-in-out infinite;
}
.pride-6 {
    animation: pride-anim6 5s ease-in-out infinite;
}


.container {
    display: flex;
    flex-direction: column;
}

.container-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.lift-topbar{
    top: -13px;
    position: relative;
    z-index: 0;
}


.container-row-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.container-row-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}


/* Specific shit */

.prev-next-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}
.next-button {
    flex: 1;
}
.button-next-prev {
    height: var(--spacing-xxl);
    border-color: var(--black);
    border-style: solid;
    border-width: var(--outline-m);
    border-radius: 0;
    cursor: pointer;
    
    font-style: italic;
    font-size: 38px;
    text-align: center;
    font-weight: 700;
    user-select: none;

    line-height: 70px;
}

.button-prev {
    min-width: 72px;
    max-width: 72px;
}

.button-prev-img{
    margin-top: 16px;
    scale: 1;
}

@media (orientation: landscape) {
    .prev-next-buttons {
        justify-content: end;
        
    }
    .next-button {
        max-width: 180px;
    }
    .button-next-prev {
        height: var(--spacing-xl);
        font-size: 29px;
        line-height: 55px;
    }
    .button-prev {
        min-width: 56px;
        max-width: 56px;
    }
    .button-prev-img{
        margin-top: 10px;
        scale: 0.75;
    }
  }




/* Slide in anim */
.slide-in {
    animation: slide-in 0.7s ease-in-out;
  }
  
  @keyframes slide-in {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    50% {
        transform: translateX(50%);
        opacity: 0;
      }
    100% {
      transform: translateX(0%);
      opacity: 100;
    }
  }

  .slide-out {
    animation: slide-out 0.7s ease-in-out;
  }
  
  @keyframes slide-out {
    0% {
      transform: translateX(0%);
      opacity: 100;
    }
    100% {
      transform: translateX(-100%);
      opacity: 0;
    }
  }

  .slide-text {
    overflow: hidden;
    position: absolute;
    width: 100%;
  }

.slide-container {
    position: relative;
    z-index: -1;
}

.rotate {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
    transform-origin: center;
  }

  .rotate-back {
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
    transform-origin: center;
  }


/* Recommendation anim */

/* Slide in anim */
.recommendation-slide-in {
    animation: recommendation-slide-in 0.7s;
  }
  
  @keyframes recommendation-slide-in {
    0% {
      transform: translateY(-50%);
      opacity: 0;
    }

    30% {
        opacity: 0;
      }
    100% {
      transform: translateY(0%);
      opacity: 100;
    }
  }


/* InfoMessage anim */
/* Slide in anim */
.info-message-slide-in {
    animation: info-message-slide-in 0.7s;
  }
  
  @keyframes info-message-slide-in {
    0% {
      transform: translateY(-150%);
      opacity: 0;
    }

    30% {
        opacity: 0;
      }
    100% {
      transform: translateY(0%);
      opacity: 100;
    }
  }