.list-item-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 12px 8px 12px;
}

.divider {
    border-bottom: var(--outline-xs) solid;
    cursor: pointer;
}