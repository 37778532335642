

@import "App.css";

.progress-container {
    display: flex;
    align-self: stretch;
    gap: 12px;
}

.progress-tab-inactive {
    background-color: var(--white);
    height: 10px;
    flex: 1;
}

.progress-tab-active {
    background-color: var(--pink);
    height: 10px;
    flex: 1;
}

.tutorial-text {
    font-weight: 700;
    font-size: 22px;
    font-style: italic;
    margin-left: var(--spacing-l);
}